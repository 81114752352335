<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker  name="date_range" @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <select v-model="search_data.download_file_tag" id="download_file_tag" name="download_file_tag" class="form-control form-control-sm">
                <option value="pdf">PDF</option>
                <option value="excel">Excel</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-download mr-1" aria-hidden="true"></i>Download</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import apiClient from '@/services/axios'

export default {
  name: 'List',
  data() {
    return {
      hubs: [],
      loading: false,
      btnLoading: false,
      search_data: {
        date_range: '',
        download_file_tag: 'excel',
      },
    }
  },
  mounted() {},
  methods: {
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    search() {
      if (this.search_data.date_range === '') {
        this.$notification.error({
          message: 'Date range is required',
        })
        return
      }
      this.btnLoading = true
      apiClient.post('api/report/requisition/pdf-download', this.search_data, { responseType: 'blob' }).then(response => {
        this.btnLoading = false
        if (response.data.size === 68) {
          this.$notification.error({
            message: 'No requisition found, in this date range',
          })
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
          const link = document.createElement('a')
          link.href = url
          if (this.search_data.download_file_tag === 'pdf') {
            link.setAttribute('download', 'product_requisition_report.pdf')
          }
          if (this.search_data.download_file_tag === 'excel') {
            link.setAttribute('download', 'product_requisition_report.xlsx')
          }
          document.body.appendChild(link)
          link.click()
        }
      }).catch(error => {
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
